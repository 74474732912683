/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib"; 

@media all and (max-width: 1023px) {

/* global
----------------------------------------------------------------------------------------------*/

.btn-plus {
	width: 45px; height: 45px;
	span {height: 45px; width: 120px;}
}
.btn-border {height: 45px;  line-height: 45px; font-size: 14px;}
.btn-block {height: 45px; line-height: 46px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
	height: 80px;
	.logo {margin: 0 0 0 100px;}
	// .right {
	// 	margin-right: 20px;
	// 	a.btn {margin-top: 18px;}
	// 	a.phone, a.online-payment {margin-top: 28px; margin-right: 20px;}
	// 	.account-nav {
	// 		height: 80px;
	// 		> a {margin-top: 28px;}
	// 	}
	// }
	.right {
		margin-right: 0; 
		a {			 
			width: auto;
			&.btn {
				font-size: 0; 
				width: 70px;
				padding: 0 25px; margin: 0; line-height: 80px; height: 80px; background-size: 100%;
				background: rgba(223,33,37,1);
				background: -moz-linear-gradient(top, rgba(223,33,37,1) 0%, rgba(219,32,36,1) 5%, rgba(174,23,26,0.96) 55%, rgba(174,23,26,0.95) 64%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(223,33,37,1)), color-stop(5%, rgba(219,32,36,1)), color-stop(55%, rgba(174,23,26,0.96)), color-stop(64%, rgba(174,23,26,0.95)));
				background: -webkit-linear-gradient(top, rgba(223,33,37,1) 0%, rgba(219,32,36,1) 5%, rgba(174,23,26,0.96) 55%, rgba(174,23,26,0.95) 64%);
				background: -o-linear-gradient(top, rgba(223,33,37,1) 0%, rgba(219,32,36,1) 5%, rgba(174,23,26,0.96) 55%, rgba(174,23,26,0.95) 64%);
				background: -ms-linear-gradient(top, rgba(223,33,37,1) 0%, rgba(219,32,36,1) 5%, rgba(174,23,26,0.96) 55%, rgba(174,23,26,0.95) 64%);
				background: linear-gradient(to bottom, rgba(223,33,37,1) 0%, rgba(219,32,36,1) 5%, rgba(174,23,26,0.96) 55%, rgba(174,23,26,0.95) 64%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df2125', endColorstr='#ae171a', GradientType=0 );
				&:after{
					display:block;
					left: 22px; 
				}
			}
			&.phone, &.online-payment {
				margin: 24px 10px 0 0;
				height: 26px;
				display: inline-block;
			}
		}
		.account-nav {
			height: 40px;
      border-left: 0;
      margin-top: 20px;
			> a {
        margin-top: 0; 
        font-size: 0 !important;
        width: auto;
        padding-right: 0; 
        &::after {
          display: none;
        }
      }
			.drop {
				@include transition(none);
				a {font-size: 12px !important; padding: 15px;}
			}
		}
	}
	&:after {left: 80px;}
	.header-right {
		margin: 0; 
		height: 100%; 
		.header-top {
			height: 100%; 
		}
	}
}
.burger-menu {top: 27px; left: 22px;}
.side-menu {
	width: 400px;padding: 80px 60px 20px; @include boxSizing(border-box); 
	.wrap_nm {padding-top: 70px;overflow: auto; height: 75vh;@include boxSizing(border-box); }
	nav {
		padding: 0;
		a {font-size: 22px;}
	}
	.lang {
        font-size: 14px; margin-left: 0px;
    }
    .search {
    	margin: 40px 0 0;
    	input {padding-bottom: 6px;}
    }
}

/* homepage
----------------------------------------------------------------------------------------------*/
.banner-home {
	margin-top: 80px; padding-left: 80px; padding-bottom: 0; background-size: cover;
	&.banner-std {
		margin-top: 80px; 
	}
	.slick-dots {
		left: -47px; 
		// bottom: 65%;
		button {margin-bottom: 40px;}
	}
	a.scroll-down {left: 35px;}
	.slide {
		> img {position: relative; width: 100%; height: 100%; object-fit: cover;min-height: 90vh;}
	}
	.caption {
		a {height: 45px; line-height: 46px; padding: 0 35px; width: auto;}
		h1 {font-size: 44px; line-height: 46px;}
	}
	&.banner-std {
		padding-left: 0;
		.wrap-caption {left: 0;}
		.scroll-down {display: none;}
		.caption {
			bottom: auto; top: 60px;
			h1,h2 {font-size: 42px; line-height: 48px; margin-bottom: 10px;}
			p {line-height: 24px;}
		}
		&:after {
			left: 0; background: rgba(0,0,0,0.35);
		}
	}
}

.home-sec {
	.wrapper {width: 730px;}
	p {line-height: 24px;}
	h2 {font-size: 26px; line-height: 34px;}
	&.h-about {
		max-width: none; padding: 40px 0 50px;background-size: 230px !important; background-position: 20px 100%;
		.left {
			width: 190px;
			h2 {font-size: 32px; line-height: 38px;}
		}
		.right {width: 515px;}
		.list-col {
			margin-top: 30px;
			.col {
				width: 240px;
				h5 {font-size: 18px;}
			}
		}
	}
	&.h-prod {
		padding: 40px 0;
		.wrapper {width: 730px;}
		&:before {left: 0;}
		.slider {
			width: 280px;
			h5 {font-size: 26px;}
		}
		.slick-arrow {
			&.slick-prev {left: -20px;}
			&.slick-next {right: -20px;}
		}
	}
	&.h-testi {
		padding-bottom: 40px; margin-bottom: 40px;
		.box {
			min-height: 200px;
			.img {display: none;}
			.text {
				margin: 0; padding: 25px 20px; width: auto;
				h6 {margin-bottom: 12px;}
			}
		}
	}
	&.h-contact {
		.left,.right {width: auto; float: none; display: block;}
		.left {
			#home-map {height: 275px;}
			.box {padding: 20px;}
		}
		.right {
			margin-top: 40px;
			> p {width: 68%;}
		}
		.box-form {
			.row {
				.col {
					width: 47%; margin-right: 6%;
					&:nth-child(2n) {margin-right: 0;}
				}
			}
		}
	}
}



/* middle
----------------------------------------------------------------------------------------------*/
.content-std {
	padding-top: 50px;
	h2,h3 {font-size: 32px; line-height: 38px;}
	.wrapper {width: 730px;}
	p {
		&.sub {font-size: 18px;}
	}
	&.faq {padding-top: 50px;}
	&.about {
		padding-top: 50px;
		h2,h3 {font-size: 32px; line-height: 38px; margin-bottom: 30px;}
		.side-image {display: none;}
		.inner-about {width: auto;}
		.list-col {
			margin-top: 40px;
			.col {
				width: 48%; margin-right: 4%;
				h5 {font-size: 18px;}
			}
		}
	}
	.list-prod {
		.box {
			width: 48%; margin-right: 4%; margin-bottom: 30px;
			&:nth-of-type(3n) {margin-right: 4%;}
			&:nth-of-type(2n) {margin-right: 0;}
		}
	}
	&.content-detail {
		.wrapper {width: 730px;}
		.image-slider {margin: 0 0 35px;}
		.title {margin-bottom: 30px;}
		.box-inquiry {
			margin: 40px 0px; height: 200px; @include boxSizing(border-box);
			> img {position: relative;  width: 100%; height: 100%; object-fit: cover;}
			.text {
				left: 50px; top: 25px;
				p {margin-top: 10px;}
				h2 {
					font-size: 26px;
					span {font-size: 12px;}
				}
			}
		}
		hr {margin: 40px 0;}
		.image-content {margin: 0 0 30px;}
		.list-gallery {
			margin: 0 0 30px;
			.box {
				> img {display: block;}
				h5 {font-size: 16px; left: 20px; right: 10px; bottom: 20px;}
			}
		}
		.nav-sub {font-size: 18px; margin-bottom: 40px;}
		.arr_drop {display: none;}
	}
	&.buy-step {
		position: relative; z-index: 1;
		.wrapper {width: 730px;}
		.box-form {
			margin-top: 60px;
			.btn-block {min-width: 150px; padding: 0 20px;}
		}
		.wrap-step {
			margin-bottom: 60px;
			.box {
				h6 {font-size: 16px; line-height: 22px; margin-top: 30px;}
				.ico {
					width: 65px; height: 65px;
					span {width: 65px; height: 65px; background-size: 62px !important;}
				}
				&:after {top: 30px;}
			}
		}
		.content_order {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex; display: flex; flex-flow: column; -webkit-flex-flow: column; -moz-flex-flow: column;
        -ms-flex-flow: column; -o-flex-flow: column;}
		.left-content {
			float: none; width: auto; display: block; order: 2; -webkit-order: 2; -moz-order: 2;
			&.top_pad {padding-top: 180px;}
		}
		.right-content {
			float: none; width: auto; display: block; order: 1; -webkit-order: 1; -moz-order: 1; margin-bottom: 50px;@include transition(all ease-out 0.5s);
			&.fixedwp {
				position: fixed; z-index: 999; top: 80px; left: 0; right: 0; @include transition(all ease-out 0.5s);
				.box-red.grey-type {margin-top: 0;}
			}
			.info {display: none; overflow: auto;}
		}
		.box-red {
			h5 {
				background: #790303; text-align: left; padding: 15px 20px 10px; position: relative;
				.arr_drop {width: 0; border-right: 6px solid transparent; border-left: 6px solid transparent; border-top: 6px solid #fff; position: absolute; right: 20px; top: 20px; bottom: 0; margin: auto;}
			}
			&.grey-type {
				h5 {
					background: #ebebeb;
					.arr_drop {border-top-color: #333;}
				}
			}
			.box {
				.left {
					font-size: 18px;
					small {
						font-size: 13px; margin: 15px 0 5px;
						&:first-of-type{margin-top: 0;}
					}
				}
				.right {
					font-size: 22px;
				}
			}
		}
	}
	.service-room-list {
		margin: 40px 0;
		.box {
			padding: 40px 0;
			.text { width: 360px; margin-left: 25px; }
			.wrap-btn {
				float: left;width: 360px; margin-left: 25px; 
				.btn-border,
				.btn-block {display: inline-block; vertical-align: top; padding: 0 20px; width: 170px;}
				.btn-border { margin-right: 10px; }
			}
		}
	}
	.box-register {
		width: 650px;
		.left {display: none;}
		.right {float: none;  display: block; margin-left: 0; width: auto;}
	}
	.box-contact {
		border: none; margin-top: 50px;
		.left {
			float: none; display: block; width: auto; height: auto; padding: 30px 20px;
			min-height: 50px;
			p {margin-bottom: 0; font-size: 18px; line-height: 26px;}
		}
		.right {float: none; display: block; width: auto; padding: 40px 10px;}
	}
	.price-type {
		white-space: nowrap; overflow: auto;
		.box {
			width: 28.5%; display: inline-block; float: none; vertical-align: top;
			.head {margin-bottom: 30px; padding: 30px 25px 0;}
		}
	}
	.support-facility {
		.left {width: auto;}
	}
	.wrap_tbl {
		overflow: auto;
		.std-table{
			&.facility {width: 1170px;}
		}
	}
	.list-location {
		.box {
			.text {
				p {}
			}
		}
	}
	.wrap-acc {margin-top: 50px;}
	.accordion {
		.h-acc {
			padding-left: 30px;
			&:after {right: 35px;}
		}
		.h-acc:before {width: 40px; height: 40px;}
		.c-acc {
			padding: 30px 35px 40px;
			h6 {font-size: 18px;}
		}
	}
	&.faq {
		margin-top: 80px;
		.wrap_block {
			margin-bottom: 60px;
			.left_text,.right_text {float: none; display: block; width: auto;}
			.left_text {margin-bottom: 30px;}
		}
	}
	
}

.thanks-page {
	width: 730px;
	.ico {width: 190px; height: 190px;}
	.text {
		padding: 80px 60px;
		h5 {font-size: 32px; margin-bottom: 30px;}
		p {font-size: 18px; line-height: 26px;}
	}
}

.pg_404 {
	width: 730px; margin-top: 195px;
	.text {
		h5 {font-size: 32px; line-height: 38px; margin: 40px 0 20px;}
		p {font-size: 18px; line-height: 24px;}
		.bk_index {font-size: 18px; margin-top: 25px;}
	}
}

.tagg {
	padding-left: 60px;
	.lbl {font-size: 16px; top: 10px;}
	ul {
		li {
			a {font-size: 15px; padding: 4px 12px;}
		}
	}
}



.src_result {width: 730px; margin: 140px auto 100px;}
.src_form {margin-bottom: 60px;}
.wrap_src {
	width: auto;
	h2 {font-size: 32px; line-height: 38px;}
	h6 {font-size: 18px; margin-bottom: 50px;}
	.list_src {
		.item {
			padding-bottom: 30px; margin-bottom: 30px;
			a {
				h4 {font-size: 22px;}
				p {font-size: 16px;}
			}
		}
	}
}
/* popup
----------------------------------------------------------------------------------------------*/

.wrap-popup {
	.box-popup {
		width: 675px;
		h4 {font-size: 30px; margin-bottom: 20px; line-height: 34px;}
		.arrow-nav.prev {left: -35px; top: 10%;}
		.arrow-nav.next {right: -35px; top: 10%;}
	}

}

.popwp{
	.in-popwp{
		top: 30px; @include transform(translateY(0));
		.ctn-pop{padding: 0 20px;}
	}
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
	padding-top: 55px; @include boxSizing(border-box); position: relative; background: #000;
	.wrapper {
		width: 730px;
		.left { 
			width: auto; display: block; float: none; margin-top: 0;
			nav {
				text-align: center;
				a {}
			}
			p {text-align: center;}
		}
		.right { position: absolute; left: 50%; @include transform(translateX(-50%)); margin-top: 0; top: 15px;}
	}
}


} /* end media */



@media all and (min-width: 767px) {

.content-std.content-detail .nav-sub {display: block !important;}

} /* end media */	